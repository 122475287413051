import React from 'react';
import { authMenu, dashboardMenu } from '../menu';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: authMenu.login.path, element: null, exact: true },
	{ path: authMenu.signUp.path, element: null, exact: true },
	{ path: authMenu.page404.path, element: null, exact: true },
	{ path: dashboardMenu.slideShow.path, element: null, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
