import {
	SET_DEPARTMENTS,
	LOADING_DEPARTMENTS,
	CREATE_DEPARTMENT,
	UPDATE_DEPARTMENT,
	DELETE_DEPARTMENT,
} from './types';

const initialState = {
	departments: [],
	error: null,
	loading: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_DEPARTMENTS:
			return {
				...state,
				loading: true,
			};

		case SET_DEPARTMENTS:
			return {
				...state,
				departments: action.payload || [],
				loading: false,
			};

		case CREATE_DEPARTMENT:
			return {
				...state,
				departments: [action.payload, ...state.departments],
			};

		case UPDATE_DEPARTMENT:
			const departmentIdx = _.findIndex(
				state.departments,
				(dep) => dep.id === action.payload.id,
			);
			return {
				departments: [
					...state.departments.slice(0, departmentIdx),
					action.payload,
					...state.departments.slice(departmentIdx + 1),
				],
				...state,
			};

		case DELETE_DEPARTMENT:
			return {
				departments: _.reject(state.departments, (dep) => dep.id === action.payload),
				...state,
			};

		default:
			return state;
	}
};

export default authReducer;
