import {
	SET_ACCOUNT_TRANSACTIONS,
	LOADING_ACCOUNT_TRANSACTIONS,
	CREATE_ACCOUNT_TRANSACTION,
	UPDATE_ACCOUNT_TRANSACTION,
	DELETE_ACCOUNT_TRANSACTION,
} from './types';

const initialState = {
	accountTransactions: [],
	error: null,
	loading: false,
};

const accountTransactionReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_ACCOUNT_TRANSACTIONS:
			return {
				...state,
				loading: true,
			};

		case SET_ACCOUNT_TRANSACTIONS:
			return {
				...state,
				accountTransactions: action.payload || [],
				loading: false,
			};

		case CREATE_ACCOUNT_TRANSACTION:
			return {
				...state,
				accountTransactions: [action.payload, ...state.accountTransactions],
			};

		case UPDATE_ACCOUNT_TRANSACTION:
			state.accountTransactions = state.accountTransactions.filter((o) => o.id != action.payload.id);
			return {
				accountTransactions: [action.payload, ...state.accountTransactions],
				...state,
			};

		case DELETE_ACCOUNT_TRANSACTION:
			state.accountTransactions = state.accountTransactions.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		default:
			return state;
	}
};

export default accountTransactionReducer;
