import {
	LOADING_SLIDES,
	SET_SLIDES,
	CREATE_SLIDE,
	UPDATE_SLIDE,
	DELETE_SLIDE,
	NOT_LOADING_SLIDES,
} from './types';

const initialState = {
	error: null,
	loading: false,
	slides: [],
};

const slideReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_SLIDES:
			return {
				...state,
				loading: true,
			};

		case NOT_LOADING_SLIDES:
			return {
				...state,
				loading: false,
			};

		case SET_SLIDES:
			return {
				...state,
				slides: action.payload || [],
				loading: false,
			};

		case CREATE_SLIDE:
			return {
				...state,
				slides: [action.payload, ...state.slides],
			};

		case UPDATE_SLIDE:
			const slideIdx = _.findIndex(state.slides, (s) => s.id === action.payload.id);
			return {
				...state,
				slides: [
					...state.slides.slice(0, slideIdx),
					action.payload,
					...state.slides.slice(slideIdx + 1),
				],
			};

		case DELETE_SLIDE:
			return {
				...state,
				slides: _.reject(state.slides, (s) => s.id === action.payload),
			};

		default:
			return state;
	}
};

export default slideReducer;
