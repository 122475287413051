import {
	LOADING_OPD,
	SET_OPD_RECORDS,
	CREATE_OPD_RECORD,
	UPDATE_OPD_RECORD,
	DELETE_OPD_RECORD,
} from './types';

const initialState = {
	error: null,
	loading: false,
	records: [],
};

const opdReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_OPD:
			return {
				...state,
				loading: true,
			};

		case SET_OPD_RECORDS:
			return {
				...state,
				records: action.payload || [],
				loading: false,
			};

		case CREATE_OPD_RECORD:
			return {
				...state,
				records: [action.payload, ...state.records],
			};

		case UPDATE_OPD_RECORD:
			const recordIdx = _.findIndex(state.records, (s) => s.id === action.payload.id);
			return {
				...state,
				records: [
					...state.records.slice(0, recordIdx),
					action.payload,
					...state.records.slice(recordIdx + 1),
				],
			};

		case DELETE_OPD_RECORD:
			return {
				...state,
				records: _.reject(state.records, (s) => s.id === action.payload),
			};

		default:
			return state;
	}
};

export default opdReducer;
