import {
	LOADING_SETTINGS,
	SET_OPD_STATUSES,
	CREATE_OPD_STATUS,
	UPDATE_OPD_STATUS,
	DELETE_OPD_STATUS,
	SET_CHIEF_COMPLAINTS,
	CREATE_CHIEF_COMPLAINT,
	UPDATE_CHIEF_COMPLAINT,
	DELETE_CHIEF_COMPLAINT,
	SET_EXAMINATIONS,
	CREATE_EXAMINATION,
	UPDATE_EXAMINATION,
	DELETE_EXAMINATION,
	SET_DIAGNOSES,
	CREATE_DIAGNOSIS,
	UPDATE_DIAGNOSIS,
	DELETE_DIAGNOSIS,
	SET_COMMUNICATION_GROUPS,
	CREATE_COMMUNICATION_GROUP,
	UPDATE_COMMUNICATION_GROUP,
	DELETE_COMMUNICATION_GROUP,
	SET_REFERRAL_TYPES,
	CREATE_REFERRAL_TYPE,
	UPDATE_REFERRAL_TYPE,
	DELETE_REFERRAL_TYPE,
	SET_EXTERNAL_REFERRERS,
	CREATE_EXTERNAL_REFERRER,
	UPDATE_EXTERNAL_REFERRER,
	DELETE_EXTERNAL_REFERRER,
	SET_TREATMENTS,
	CREATE_TREATMENT,
	UPDATE_TREATMENT,
	DELETE_TREATMENT,
	SET_PRESCRIPTION_TEMPLATES,
	CREATE_PRESCRIPTION_TEMPLATE,
	UPDATE_PRESCRIPTION_TEMPLATE,
	DELETE_PRESCRIPTION_TEMPLATE,
	SET_NOTES,
	CREATE_NOTE,
	UPDATE_NOTE,
	DELETE_NOTE,
	SET_INVESTIGATIONS,
	CREATE_INVESTIGATION,
	UPDATE_INVESTIGATION,
	DELETE_INVESTIGATION,
	SET_ORAL_EXAMINATIONS,
	CREATE_ORAL_EXAMINATION,
	UPDATE_ORAL_EXAMINATION,
	DELETE_ORAL_EXAMINATION,
} from './types';

const initialState = {
	opdStatuses: [],
	chiefComplaints: [],
	examinations: [],
	diagnoses: [],
	communicationGroups: [],
	referralTypes: [],
	externalReferrers: [],
	treatments: [],
	prescriptionTemplates: [],
	notes: [],
	investigations: [],
	oralExaminations: [],
	error: null,
	loading: false,
};

const pharmacyReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_SETTINGS:
			return {
				...state,
				loading: true,
			};

		case SET_OPD_STATUSES:
			return {
				...state,
				opdStatuses: action.payload || [],
				loading: false,
			};

		case CREATE_OPD_STATUS:
			return {
				...state,
				opdStatuses: [action.payload, ...state.opdStatuses],
			};

		case UPDATE_OPD_STATUS:
			const statusIdx = _.findIndex(
				state.opdStatuses,
				(s) => s.id === action.payload.opdStatusId,
			);
			return {
				...state,
				opdStatuses: [
					...state.opdStatuses.slice(0, statusIdx),
					{ ...state.opdStatuses[statusIdx], ...action.payload },
					...state.opdStatuses.slice(statusIdx + 1),
				],
			};

		case DELETE_OPD_STATUS:
			return {
				...state,
				opdStatuses: _.reject(state.opdStatuses, (s) => s.id === action.payload),
			};

		case SET_CHIEF_COMPLAINTS:
			return {
				...state,
				chiefComplaints: action.payload || [],
				loading: false,
			};

		case CREATE_CHIEF_COMPLAINT:
			return {
				...state,
				chiefComplaints: [action.payload, ...state.chiefComplaints],
			};

		case UPDATE_CHIEF_COMPLAINT:
			const complaintIdx = _.findIndex(
				state.chiefComplaints,
				(s) => s.id === action.payload.chiefComplaintId,
			);
			return {
				...state,
				chiefComplaints: [
					...state.chiefComplaints.slice(0, complaintIdx),
					{ ...state.chiefComplaints[complaintIdx], ...action.payload },
					...state.chiefComplaints.slice(complaintIdx + 1),
				],
			};

		case DELETE_CHIEF_COMPLAINT:
			return {
				...state,
				chiefComplaints: _.reject(state.chiefComplaints, (s) => s.id === action.payload),
			};

		case SET_EXAMINATIONS:
			return {
				...state,
				examinations: action.payload || [],
				loading: false,
			};

		case CREATE_EXAMINATION:
			return {
				...state,
				examinations: [action.payload, ...state.examinations],
			};

		case UPDATE_EXAMINATION:
			const examinationIdx = _.findIndex(
				state.examinations,
				(s) => s.id === action.payload.examinationId,
			);
			return {
				...state,
				examinations: [
					...state.examinations.slice(0, examinationIdx),
					{ ...state.examinations[examinationIdx], ...action.payload },
					...state.examinations.slice(examinationIdx + 1),
				],
			};

		case DELETE_EXAMINATION:
			return {
				...state,
				examinations: _.reject(
					state.examinations,
					(s) => s.examinationId === action.payload,
				),
			};

		case SET_DIAGNOSES:
			return {
				...state,
				diagnoses: action.payload || [],
				loading: false,
			};

		case CREATE_DIAGNOSIS:
			return {
				...state,
				diagnoses: [action.payload, ...state.diagnoses],
			};

		case UPDATE_DIAGNOSIS:
			const diagnosisIdx = _.findIndex(
				state.diagnoses,
				(s) => s.id === action.payload.diagnosesId,
			);
			return {
				...state,
				diagnoses: [
					...state.diagnoses.slice(0, diagnosisIdx),
					{ ...state.diagnoses[diagnosisIdx], ...action.payload },
					...state.diagnoses.slice(diagnosisIdx + 1),
				],
			};

		case DELETE_DIAGNOSIS:
			return {
				...state,
				diagnoses: _.reject(state.diagnoses, (s) => s.diagnosesId === action.payload),
			};

		case SET_COMMUNICATION_GROUPS:
			return {
				...state,
				communicationGroups: action.payload || [],
				loading: false,
			};

		case CREATE_COMMUNICATION_GROUP:
			return {
				...state,
				communicationGroups: [action.payload, ...state.communicationGroups],
			};

		case UPDATE_COMMUNICATION_GROUP:
			const commGroupIdx = _.findIndex(
				state.communicationGroups,
				(s) => s.id === action.payload.communicationGroupId,
			);
			return {
				...state,
				communicationGroups: [
					...state.communicationGroups.slice(0, commGroupIdx),
					{ ...state.communicationGroups[commGroupIdx], ...action.payload },
					...state.communicationGroups.slice(commGroupIdx + 1),
				],
			};

		case DELETE_COMMUNICATION_GROUP:
			return {
				...state,
				communicationGroups: _.reject(
					state.communicationGroups,
					(s) => s.id === action.payload,
				),
			};

		case SET_REFERRAL_TYPES:
			return {
				...state,
				referralTypes: action.payload || [],
				loading: false,
			};

		case CREATE_REFERRAL_TYPE:
			return {
				...state,
				referralTypes: [action.payload, ...state.referralTypes],
			};

		case UPDATE_REFERRAL_TYPE:
			const refTypeIdx = _.findIndex(
				state.referralTypes,
				(s) => s.id === action.payload.referralTypeId,
			);
			return {
				...state,
				referralTypes: [
					...state.referralTypes.slice(0, refTypeIdx),
					{ ...state.referralTypes[refTypeIdx], ...action.payload },
					...state.referralTypes.slice(refTypeIdx + 1),
				],
			};

		case DELETE_REFERRAL_TYPE:
			return {
				...state,
				referralTypes: _.reject(state.referralTypes, (s) => s.id === action.payload),
			};

		case SET_EXTERNAL_REFERRERS:
			return {
				...state,
				externalReferrers: action.payload || [],
				loading: false,
			};

		case CREATE_EXTERNAL_REFERRER:
			return {
				...state,
				externalReferrers: [action.payload, ...state.externalReferrers],
			};

		case UPDATE_EXTERNAL_REFERRER:
			const exRefIdx = _.findIndex(
				state.externalReferrers,
				(s) => s.id === action.payload.externalReferrerId,
			);
			return {
				...state,
				externalReferrers: [
					...state.externalReferrers.slice(0, exRefIdx),
					{ ...state.externalReferrers[exRefIdx], ...action.payload },
					...state.externalReferrers.slice(exRefIdx + 1),
				],
			};

		case DELETE_EXTERNAL_REFERRER:
			return {
				...state,
				externalReferrers: _.reject(
					state.externalReferrers,
					(s) => s.id === action.payload,
				),
			};

		case SET_TREATMENTS:
			return {
				...state,
				treatments: action.payload || [],
				loading: false,
			};

		case CREATE_TREATMENT:
			return {
				...state,
				treatments: [action.payload, ...state.treatments],
			};

		case UPDATE_TREATMENT:
			const treatmentIdx = _.findIndex(
				state.treatments,
				(s) => s.id === action.payload.treatmentId,
			);
			return {
				...state,
				treatments: [
					...state.treatments.slice(0, treatmentIdx),
					{ ...state.treatments[treatmentIdx], ...action.payload },
					...state.treatments.slice(treatmentIdx + 1),
				],
			};

		case DELETE_TREATMENT:
			return {
				...state,
				prescriptionTemplates: _.reject(
					state.prescriptionTemplates,
					(s) => s.id === action.payload,
				),
			};

		case SET_PRESCRIPTION_TEMPLATES:
			return {
				...state,
				prescriptionTemplates: action.payload || [],
				loading: false,
			};

		case CREATE_PRESCRIPTION_TEMPLATE:
			return {
				...state,
				prescriptionTemplates: [action.payload, ...state.prescriptionTemplates],
			};

		case UPDATE_PRESCRIPTION_TEMPLATE:
			const prescTempIdx = _.findIndex(
				state.prescriptionTemplates,
				(s) => s.id === action.payload.prescriptionTemplateId,
			);
			return {
				...state,
				prescriptionTemplates: [
					...state.prescriptionTemplates.slice(0, prescTempIdx),
					{ ...state.prescriptionTemplates[prescTempIdx], ...action.payload },
					...state.prescriptionTemplates.slice(prescTempIdx + 1),
				],
			};

		case DELETE_PRESCRIPTION_TEMPLATE:
			return {
				...state,
				prescriptionTemplates: _.reject(
					state.prescriptionTemplates,
					(s) => s.id === action.payload,
				),
			};

		case SET_NOTES:
			return {
				...state,
				notes: action.payload || [],
				loading: false,
			};

		case CREATE_NOTE:
			return {
				...state,
				notes: [action.payload, ...state.notes],
			};

		case UPDATE_NOTE:
			const noteIdx = _.findIndex(
				state.notes,
				(s) => s.id === action.payload.treatmentTemplateId,
			);
			return {
				...state,
				notes: [
					...state.notes.slice(0, noteIdx),
					{ ...state.notes[noteIdx], ...action.payload },
					...state.notes.slice(noteIdx + 1),
				],
			};

		case DELETE_NOTE:
			return {
				...state,
				notes: _.reject(state.notes, (s) => s.id === action.payload),
			};

		case SET_INVESTIGATIONS:
			return {
				...state,
				investigations: action.payload || [],
				loading: false,
			};

		case CREATE_INVESTIGATION:
			return {
				...state,
				investigations: [action.payload, ...state.investigations],
			};

		case UPDATE_INVESTIGATION:
			const investigationIdx = _.findIndex(
				state.investigations,
				(s) => s.id === action.payload.investigationId,
			);
			return {
				...state,
				investigations: [
					...state.investigations.slice(0, investigationIdx),
					{ ...state.investigations[investigationIdx], ...action.payload },
					...state.investigations.slice(investigationIdx + 1),
				],
			};

		case DELETE_INVESTIGATION:
			return {
				...state,
				investigations: _.reject(state.investigations, (s) => s.id === action.payload),
			};

		case SET_ORAL_EXAMINATIONS:
			return {
				...state,
				oralExaminations: action.payload || [],
				loading: false,
			};

		case CREATE_ORAL_EXAMINATION:
			return {
				...state,
				oralExaminations: [action.payload, ...state.oralExaminations],
			};

		case UPDATE_ORAL_EXAMINATION:
			const oralExamIdx = _.findIndex(
				state.oralExaminations,
				(s) => s.id === action.payload.oralExaminationId,
			);
			return {
				...state,
				oralExaminations: [
					...state.oralExaminations.slice(0, oralExamIdx),
					{ ...state.oralExaminations[oralExamIdx], ...action.payload },
					...state.oralExaminations.slice(oralExamIdx + 1),
				],
			};

		case DELETE_ORAL_EXAMINATION:
			return {
				...state,
				oralExaminations: _.reject(state.oralExaminations, (s) => s.id === action.payload),
			};

		default:
			return state;
	}
};

export default pharmacyReducer;
