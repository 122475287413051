import {
	SET_PURCHASE_VOUCHERS,
	LOADING_PURCHASE_VOUCHERS,
	CREATE_PURCHASE_VOUCHER,
	UPDATE_PURCHASE_VOUCHER,
	DELETE_PURCHASE_VOUCHER,
} from './types';

const initialState = {
	purchaseVouchers: [],
	error: null,
	loading: false,
};

const purchaseVoucherReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PURCHASE_VOUCHERS:
			return {
				...state,
				loading: true,
			};

		case SET_PURCHASE_VOUCHERS:
			return {
				...state,
				purchaseVouchers: action.payload || [],
				loading: false,
			};

		case CREATE_PURCHASE_VOUCHER:
			return {
				...state,
				purchaseVouchers: [action.payload, ...state.purchaseVouchers],
			};

		case UPDATE_PURCHASE_VOUCHER:
			state.purchaseVouchers = state.purchaseVouchers.filter(
				(o) => o.id != action.payload.id,
			);
			return {
				purchaseVouchers: [action.payload, ...state.purchaseVouchers],
				...state,
			};

		case DELETE_PURCHASE_VOUCHER:
			state.purchaseVouchers = state.purchaseVouchers.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		default:
			return state;
	}
};

export default purchaseVoucherReducer;
