import {
	SET_ORGANISATIONS,
	CREATE_ORGANISATION,
	UPDATE_ORGANISATION,
	DELETE_ORGANISATION,
	SET_SELECTED_ORGANISATION,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';

export const getOrganisations = () => async (dispatch) => {
	try {
		const res = await axios.get('/organisation-ehr');
		if (res.data.success) {
			dispatch({
				type: SET_ORGANISATIONS,
				payload: res.data.organisations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOrganisation = async (orgId) => {
	try {
		const res = await axios.get(`/organisation-ehr/${orgId}`);
		if (res.data.success) {
			return res.data.organisation;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const setInitialOrganisation = (org) => async (dispatch) => {
	if (org) {
		axios.defaults.headers.common.organisationId = org.id;
		dispatch({
			type: SET_SELECTED_ORGANISATION,
			payload: org,
		});
		return;
	}
	try {
		const res = await axios.get('/organisation-ehr');
		if (res.data.success) {
			if (res.data.organisations.length > 0) {
				axios.defaults.headers.common.organisationId = res.data.organisations[0]?.id;
				dispatch({
					type: SET_SELECTED_ORGANISATION,
					payload: res.data.organisations[0],
				});
			}
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOrganisation = (newOrg, navigate) => async (dispatch) => {
	try {
		const res = await axios.post('/organisation-ehr', newOrg);
		if (res.data.success) {
			showNotification('Success', 'The organisation is created successfully', 'success');
			dispatch(getOrganisations());
			navigate(`/${dashboardMenu.settings.subMenu.viewOrganisations.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOrganisation = (updateBody, navigate) => async (dispatch, getState) => {
	try {
		const res = await axios.patch('/organisation-ehr', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The organisation is updated successfully', 'success');
			dispatch(getOrganisations());

			const selectedOrg = getState().profile.selectedOrg;

			if (updateBody?.get('organisationId') == selectedOrg?.id) {
				const resOrg = await getOrganisation(selectedOrg.id);
				dispatch(setInitialOrganisation(resOrg || selectedOrg));
			}

			navigate(`/${dashboardMenu.settings.subMenu.viewOrganisations.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOrganisation = (organisationId) => async (dispatch) => {
	try {
		const res = await axios.delete('/organisation-ehr', { data: { organisationId } });
		if (res.data.success) {
			dispatch({
				type: DELETE_ORGANISATION,
				payload: organisationId,
			});
			showNotification(
				'Success',
				'The organisation has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};
