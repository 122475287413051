import _ from 'lodash';
import {
	SET_AMENITIES,
	LOADING_AMENITIES,
	CREATE_AMENITY,
	UPDATE_AMENITY,
	DELETE_AMENITY,
} from './types';

const initialState = {
	amenities: [],
	error: null,
	loading: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_AMENITIES:
			return {
				...state,
				loading: true,
			};

		case SET_AMENITIES:
			return {
				...state,
				amenities: action.payload || [],
				loading: false,
			};

		case CREATE_AMENITY:
			return {
				...state,
				amenities: [action.payload, ...state.amenities],
			};

		case UPDATE_AMENITY:
			const amenityIdx = _.findIndex(state.amenities, (p) => p.id === action.payload.id);
			return {
				amenities: [
					...state.amenities.slice(0, amenityIdx),
					action.payload,
					...state.amenities.slice(amenityIdx + 1),
				],
				...state,
			};

		case DELETE_AMENITY:
			return {
				amenities: _.reject(state.amenities, (p) => p.id === action.payload.id),
				...state,
			};

		default:
			return state;
	}
};

export default authReducer;
