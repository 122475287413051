// Auth Reducer Types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_OTP_KEY = 'SET_OTP_KEY';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOADING_USER = 'LOADING_USER';

export const SET_SELECTED_ORGANISATION = 'SET_SELECTED_ORGANISATION';

export const NOT_LOADING_SLIDES = 'NOT_LOADING_SLIDES';
export const LOADING_SLIDES = 'LOADING_SLIDES';
export const SET_SLIDES = 'SET_SLIDES';
export const CREATE_SLIDE = 'CREATE_SLIDE';
export const UPDATE_SLIDE = 'UPDATE_SLIDE';
export const DELETE_SLIDE = 'DELETE_SLIDE';

export const LOADING_QUALIFICATIONS = 'LOADING_QUALIFICATIONS';
export const SET_QUALIFICATIONS = 'SET_QUALIFICATIONS';
export const CREATE_QUALIFICATION = 'CREATE_QUALIFICATION';
export const UPDATE_QUALIFICATION = 'UPDATE_QUALIFICATION';
export const DELETE_QUALIFICATION = 'DELETE_QUALIFICATION';

export const LOADING_INCLINIC_SLOTS = 'LOADING_INCLINIC_SLOTS';
export const SET_INCLINIC_SLOTS = 'SET_INCLINIC_SLOTS';
export const CREATE_INCLINIC_SLOT = 'CREATE_INCLINIC_SLOT';
export const UPDATE_INCLINIC_SLOT = 'UPDATE_INCLINIC_SLOT';
export const DELETE_INCLINIC_SLOT = 'DELETE_INCLINIC_SLOT';

export const LOADING_PRESCRIPTION_PAD = 'LOADING_PRESCRIPTION_PAD';
export const SET_PRESCRIPTION_PAD = 'SET_PRESCRIPTION_PAD';
export const CREATE_PRESCRIPTION_PAD = 'CREATE_PRESCRIPTION_PAD';
export const UPDATE_PRESCRIPTION_PAD = 'UPDATE_PRESCRIPTION_PAD';
export const DELETE_PRESCRIPTION_PAD = 'DELETE_PRESCRIPTION_PAD';

export const LOADING_BILLING_PAD = 'LOADING_BILLING_PAD';
export const SET_BILLING_PAD = 'SET_BILLING_PAD';
export const CREATE_BILLING_PAD = 'CREATE_BILLING_PAD';
export const UPDATE_BILLING_PAD = 'UPDATE_BILLING_PAD';
export const DELETE_BILLING_PAD = 'DELETE_BILLING_PAD';

export const LOADING_TELECON_SLOTS = 'LOADING_TELECON_SLOTS';
export const SET_TELECON_SLOTS = 'SET_TELECON_SLOTS';
export const CREATE_TELECON_SLOT = 'CREATE_TELECON_SLOT';
export const UPDATE_TELECON_SLOT = 'UPDATE_TELECON_SLOT';
export const DELETE_TELECON_SLOT = 'DELETE_TELECON_SLOT';

export const LOADING_APPOINTMENTS = 'LOADING_APPOINTMENTS';
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';

export const LOADING_ORGANISATIONS = 'LOADING_ORGANISATIONS';
export const SET_ORGANISATIONS = 'SET_ORGANISATIONS';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const DELETE_ORGANISATION = 'DELETE_ORGANISATION';

export const LOADING_SPECIALITIES = 'LOADING_SPECIALITIES';
export const SET_SPECIALITIES = 'SET_SPECIALITIES';
export const CREATE_SPECIALITY = 'CREATE_SPECIALITY';
export const UPDATE_SPECIALITY = 'UPDATE_SPECIALITY';
export const DELETE_SPECIALITY = 'DELETE_SPECIALITY';

export const LOADING_DEPARTMENTS = 'LOADING_DEPARTMENTS';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';

export const LOADING_DOCTORS = 'LOADING_DOCTORS';
export const SET_DOCTORS = 'SET_DOCTORS';
export const CREATE_DOCTOR = 'CREATE_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';
export const DELETE_DOCTOR = 'DELETE_DOCTOR';

export const LOADING_PATIENTS = 'LOADING_PATIENTS';
export const SET_PATIENTS = 'SET_PATIENTS';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';

export const SET_PATIENT_EXAMINATIONS = 'SET_PATIENT_EXAMINATIONS';
export const CREATE_PATIENT_EXAMINATION = 'CREATE_PATIENT_EXAMINATION';
export const UPDATE_PATIENT_EXAMINATION = 'UPDATE_PATIENT_EXAMINATION';
export const DELETE_PATIENT_EXAMINATION = 'DELETE_PATIENT_EXAMINATION';

export const SET_PATIENT_TREATMENT_PLANS = 'SET_PATIENT_TREATMENT_PLANS';
export const CREATE_PATIENT_TREATMENT_PLAN = 'CREATE_PATIENT_TREATMENT_PLAN';
export const UPDATE_PATIENT_TREATMENT_PLAN = 'UPDATE_PATIENT_TREATMENT_PLAN';
export const DELETE_PATIENT_TREATMENT_PLAN = 'DELETE_PATIENT_TREATMENT_PLAN';

export const SET_PATIENT_TREATMENTS = 'SET_PATIENT_TREATMENTS';
export const CREATE_PATIENT_TREATMENT = 'CREATE_PATIENT_TREATMENT';
export const UPDATE_PATIENT_TREATMENT = 'UPDATE_PATIENT_TREATMENT';
export const DELETE_PATIENT_TREATMENT = 'DELETE_PATIENT_TREATMENT';

export const SET_PATIENT_PRESCRIPTIONS = 'SET_PATIENT_PRESCRIPTIONS';
export const CREATE_PATIENT_PRESCRIPTION = 'CREATE_PATIENT_PRESCRIPTION';
export const UPDATE_PATIENT_PRESCRIPTION = 'UPDATE_PATIENT_PRESCRIPTION';
export const DELETE_PATIENT_PRESCRIPTION = 'DELETE_PATIENT_PRESCRIPTION';

export const SET_PATIENT_BILLS = 'SET_PATIENT_BILLS';
export const CREATE_PATIENT_BILL = 'CREATE_PATIENT_BILL';
export const UPDATE_PATIENT_BILL = 'UPDATE_PATIENT_BILL';
export const DELETE_PATIENT_BILL = 'DELETE_PATIENT_BILL';

export const SET_PATIENT_PAYMENTS = 'SET_PATIENT_PAYMENTS';
export const CREATE_PATIENT_PAYMENT = 'CREATE_PATIENT_PAYMENT';
export const UPDATE_PATIENT_PAYMENT = 'UPDATE_PATIENT_PAYMENT';
export const DELETE_PATIENT_PAYMENT = 'DELETE_PATIENT_PAYMENT';

export const LOADING_PHARMACIES = 'LOADING_PHARMACIES';
export const SET_PHARMACIES = 'SET_PHARMACIES';
export const CREATE_PHARMACY = 'CREATE_PHARMACY';
export const UPDATE_PHARMACY = 'UPDATE_PHARMACY';
export const DELETE_PHARMACY = 'DELETE_PHARMACY';

export const LOADING_PHARMACY_VENDORS = 'LOADING_PHARMACY_VENDORS';
export const SET_PHARMACY_VENDORS = 'SET_PHARMACY_VENDORS';
export const CREATE_PHARMACY_VENDOR = 'CREATE_PHARMACY_VENDOR';
export const UPDATE_PHARMACY_VENDOR = 'UPDATE_PHARMACY_VENDOR';
export const DELETE_PHARMACY_VENDOR = 'DELETE_PHARMACY_VENDOR';

export const LOADING_PURCHASE_VOUCHERS = 'LOADING_PURCHASE_VOUCHERS';
export const SET_PURCHASE_VOUCHERS = 'SET_PURCHASE_VOUCHERS';
export const CREATE_PURCHASE_VOUCHER = 'CREATE_PURCHASE_VOUCHER';
export const UPDATE_PURCHASE_VOUCHER = 'UPDATE_PURCHASE_VOUCHER';
export const DELETE_PURCHASE_VOUCHER = 'DELETE_PURCHASE_VOUCHER';

export const LOADING_SALE_VOUCHERS = 'LOADING_SALE_VOUCHERS';
export const SET_SALE_VOUCHERS = 'SET_SALE_VOUCHERS';
export const CREATE_SALE_VOUCHER = 'CREATE_SALE_VOUCHER';
export const UPDATE_SALE_VOUCHER = 'UPDATE_SALE_VOUCHER';
export const DELETE_SALE_VOUCHER = 'DELETE_SALE_VOUCHER';

export const LOADING_INVENTORIES = 'LOADING_INVENTORIES';
export const SET_INVENTORIES = 'SET_INVENTORIES';
export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';

export const LOADING_AMENITIES = 'LOADING_AMENITIES';
export const SET_AMENITIES = 'SET_AMENITIES';
export const CREATE_AMENITY = 'CREATE_AMENITY';
export const UPDATE_AMENITY = 'UPDATE_AMENITY';
export const DELETE_AMENITY = 'DELETE_AMENITY';

export const LOADING_OPD = 'LOADING_OPD';

export const SET_OPD_RECORDS = 'SET_OPD_RECORDS';
export const CREATE_OPD_RECORD = 'CREATE_OPD_RECORD';
export const UPDATE_OPD_RECORD = 'UPDATE_OPD_RECORD';
export const DELETE_OPD_RECORD = 'DELETE_OPD_RECORD';

export const LOADING_STAFF = 'LOADING_STAFF';
export const SET_STAFF = 'SET_STAFF_RECORDS';
export const CREATE_STAFF = 'CREATE_STAFF_RECORD';
export const UPDATE_STAFF = 'UPDATE_STAFF_RECORD';
export const DELETE_STAFF = 'DELETE_STAFF_RECORD';

export const SET_STAFF_ATTENDANCE = 'SET_STAFF_ATTENDANCE_RECORDS';
export const CREATE_STAFF_ATTENDANCE = 'CREATE_STAFF_ATTENDANCE_RECORD';
export const UPDATE_STAFF_ATTENDANCE = 'UPDATE_STAFF_ATTENDANCE_RECORD';
export const DELETE_STAFF_ATTENDANCE = 'DELETE_STAFF_ATTENDANCE_RECORD';

export const SET_STAFF_SALARY = 'SET_STAFF_ATTENDANCE_RECORDS';
export const CREATE_STAFF_SALARY = 'CREATE_STAFF_ATTENDANCE_RECORD';
export const UPDATE_STAFF_SALARY = 'UPDATE_STAFF_ATTENDANCE_RECORD';
export const DELETE_STAFF_SALARY = 'DELETE_STAFF_ATTENDANCE_RECORD';

export const LOADING_SETTINGS = 'LOADING_SETTINGS';

export const SET_OPD_STATUSES = 'SET_OPD_STATUSES';
export const CREATE_OPD_STATUS = 'CREATE_OPD_STATUS';
export const UPDATE_OPD_STATUS = 'UPDATE_OPD_STATUS';
export const DELETE_OPD_STATUS = 'DELETE_OPD_STATUS';

export const SET_CHIEF_COMPLAINTS = 'SET_CHIEF_COMPLAINTS';
export const CREATE_CHIEF_COMPLAINT = 'CREATE_CHIEF_COMPLAINT';
export const UPDATE_CHIEF_COMPLAINT = 'UPDATE_CHIEF_COMPLAINT';
export const DELETE_CHIEF_COMPLAINT = 'DELETE_CHIEF_COMPLAINT';

export const SET_EXAMINATIONS = 'SET_EXAMINATIONS';
export const CREATE_EXAMINATION = 'CREATE_EXAMINATION';
export const UPDATE_EXAMINATION = 'UPDATE_EXAMINATION';
export const DELETE_EXAMINATION = 'DELETE_EXAMINATION';

export const SET_DIAGNOSES = 'SET_DIAGNOSES';
export const CREATE_DIAGNOSIS = 'CREATE_DIAGNOSIS';
export const UPDATE_DIAGNOSIS = 'UPDATE_DIAGNOSIS';
export const DELETE_DIAGNOSIS = 'DELETE_DIAGNOSIS';

export const SET_COMMUNICATION_GROUPS = 'SET_COMMUNICATION_GROUPS';
export const CREATE_COMMUNICATION_GROUP = 'CREATE_COMMUNICATION_GROUP';
export const UPDATE_COMMUNICATION_GROUP = 'UPDATE_COMMUNICATION_GROUP';
export const DELETE_COMMUNICATION_GROUP = 'DELETE_COMMUNICATION_GROUP';

export const SET_REFERRAL_TYPES = 'SET_REFERRAL_TYPES';
export const CREATE_REFERRAL_TYPE = 'CREATE_REFERRAL_TYPE';
export const UPDATE_REFERRAL_TYPE = 'UPDATE_REFERRAL_TYPE';
export const DELETE_REFERRAL_TYPE = 'DELETE_REFERRAL_TYPE';

export const SET_EXTERNAL_REFERRERS = 'SET_EXTERNAL_REFERRERS';
export const CREATE_EXTERNAL_REFERRER = 'CREATE_EXTERNAL_REFERRER';
export const UPDATE_EXTERNAL_REFERRER = 'UPDATE_EXTERNAL_REFERRER';
export const DELETE_EXTERNAL_REFERRER = 'DELETE_EXTERNAL_REFERRER';

export const SET_TREATMENTS = 'SET_TREATMENTS';
export const CREATE_TREATMENT = 'CREATE_TREATMENT';
export const UPDATE_TREATMENT = 'UPDATE_TREATMENT';
export const DELETE_TREATMENT = 'DELETE_TREATMENT';

export const SET_PRESCRIPTION_TEMPLATES = 'SET_PRESCRIPTION_TEMPLATES';
export const CREATE_PRESCRIPTION_TEMPLATE = 'CREATE_PRESCRIPTION_TEMPLATE';
export const UPDATE_PRESCRIPTION_TEMPLATE = 'UPDATE_PRESCRIPTION_TEMPLATE';
export const DELETE_PRESCRIPTION_TEMPLATE = 'DELETE_PRESCRIPTION_TEMPLATE';

export const SET_NOTES = 'SET_NOTES';
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

export const SET_INVESTIGATIONS = 'SET_INVESTIGATIONS';
export const CREATE_INVESTIGATION = 'CREATE_INVESTIGATION';
export const UPDATE_INVESTIGATION = 'UPDATE_INVESTIGATION';
export const DELETE_INVESTIGATION = 'DELETE_INVESTIGATION';

export const SET_ORAL_EXAMINATIONS = 'SET_ORAL_EXAMINATIONS';
export const CREATE_ORAL_EXAMINATION = 'CREATE_ORAL_EXAMINATION';
export const UPDATE_ORAL_EXAMINATION = 'UPDATE_ORAL_EXAMINATION';
export const DELETE_ORAL_EXAMINATION = 'DELETE_ORAL_EXAMINATION';

export const LOADING_ACCOUNT_VENDORS = 'LOADING_ACCOUNT_VENDORS';
export const SET_ACCOUNT_VENDORS = 'SET_ACCOUNT_VENDORS';
export const CREATE_ACCOUNT_VENDOR = 'CREATE_ACCOUNT_VENDOR';
export const UPDATE_ACCOUNT_VENDOR = 'UPDATE_ACCOUNT_VENDOR';
export const DELETE_ACCOUNT_VENDOR = 'DELETE_ACCOUNT_VENDOR';

export const LOADING_ACCOUNT_TRANSACTIONS = 'LOADING_ACCOUNT_TRANSACTIONS';
export const SET_ACCOUNT_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS';
export const CREATE_ACCOUNT_TRANSACTION = 'CREATE_ACCOUNT_TRANSACTIONS';
export const UPDATE_ACCOUNT_TRANSACTION = 'UPDATE_ACCOUNT_TRANSACTIONS';
export const DELETE_ACCOUNT_TRANSACTION = 'DELETE_ACCOUNT_TRANSACTIONS';
