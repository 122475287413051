import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import USERS from '../../common/data/userDummyData';
import { demoPages } from '../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import { staticUrl } from '../../helpers/helpers';

import { logout } from '../../actions/authActions';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const navigate = useNavigate();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<UserAvatar
						srcSet={
							user?.Vendor_detail?.profileImage &&
							`${staticUrl}/doctor-profile/${user.Vendor_detail.profileImage}`
						}
						src={USERS.JOHN.src}
					/>
					<div className='user-info'>
						<div className='user-name'>
							{user?.Vendor_detail?.name ? `Dr ${user?.Vendor_detail?.name}` : '-'}
						</div>
						<div className='user-sub-title'>Doctor</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button icon='AccountBox' onClick={() => navigate('/profile')}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button icon='Logout' onClick={handleLogout}>
						Logout
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
