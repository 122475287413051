import React from 'react';
import PropTypes from 'prop-types';
import LogoImg from '../assets/logos/logo_black.png';

const Logo = ({ width, height }) => <img src={LogoImg} width={width} height={height} alt='logo' />;

Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 350,
	height: 100,
};

export default Logo;
