import {
	SET_INCLINIC_SLOTS,
	SET_PRESCRIPTION_PAD,
	SET_BILLING_PAD,
	SET_TELECON_SLOTS,
	SET_QUALIFICATIONS,
	LOADING_USER,
	SET_USER,
	DELETE_QUALIFICATION,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { setInitialOrganisation } from './organisationActions';

export const getUserData = () => async (dispatch) => {
	dispatch({ type: LOADING_USER });
	try {
		console.log('running getUserData');
		const res = await axios.get('/doctor/profile');
		if (res.data.success) {
			if (res.data.detail?.userType === 'staff') {
				dispatch(
					setInitialOrganisation(res.data.detail?.Vendor_organisations[0]?.Organisation),
				);
			}
			dispatch({
				type: SET_USER,
				payload: res.data.detail,
			});
		}
	} catch (err) {
		console.log(err);
		dispatch(logout());
	}
};

export const updateUserData = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/updateProfile', updateBody);
		if (res.data.success) {
			dispatch(getUserData());
			showNotification('Success', 'The profile details are updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateProfileImg = (formdata) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/image', formdata);
		if (res.data.success) {
			showNotification(
				'Success',
				'The profile image is updated successfully. Please refresh.',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateRegistrationCertificate = (formdata) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/certificate', formdata);
		if (res.data.success) {
			dispatch(getUserData());
			showNotification(
				'Success',
				'The registration certificate is updated successfully.',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getUserQualifications = () => async (dispatch) => {
	try {
		const res = await axios.get('/qualification');
		if (res.data.success) {
			dispatch({
				type: SET_QUALIFICATIONS,
				payload: res.data.qualifications,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createUserQualification = (newQualification) => async (dispatch) => {
	try {
		const res = await axios.post('/qualification', newQualification);
		if (res.data.success) {
			dispatch(getUserQualifications());
			showNotification('Success', 'The degree has been added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateUserQualification = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/qualification', updateBody);
		if (res.data.success) {
			dispatch(getUserQualifications());
			showNotification('Success', 'The qualification is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteUserQualification = (qualificationId) => async (dispatch) => {
	console.log(qualificationId);
	try {
		const res = await axios.delete('/qualification', { data: { qualificationId } });
		if (res.data.success) {
			dispatch({
				type: DELETE_QUALIFICATION,
				payload: qualificationId,
			});
			showNotification(
				'Success',
				'The qualification has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const createUserAppointmentSystem = (newApSys) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/inClinicType', newApSys);
		if (res.data.success) {
			dispatch(getUserData());
			showNotification(
				'Success',
				'The appointment system has been altered successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getInclinicSlots = () => async (dispatch) => {
	console.log('running getInclinicSlots');
	try {
		const res = await axios.get('/slots/inclinic');
		if (res.data.success) {
			dispatch({
				type: SET_INCLINIC_SLOTS,
				payload: res.data.slots,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createInclinicSlot = (newSlot) => async (dispatch) => {
	try {
		const res = await axios.post('/slots/inclinic', newSlot);
		if (res.data.success) {
			dispatch(getInclinicSlots());
			showNotification('Success', 'The Inclinic Slot is created successfully', 'success');
			// dispatch({
			// 	type: CREATE_INCLINIC_SLOT,
			// 	payload: newDep,
			// });
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateInclinicSlot = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/slots/inclinic', updateBody);
		if (res.data.success) {
			dispatch(getInclinicSlots());
			// dispatch({
			// 	type: UPDATE_INCLINIC_SLOT,
			// 	payload: updateBody,
			// });
			showNotification('Success', 'The inclinicSlot is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteInclinicSlot = (slotTimeId) => async (dispatch) => {
	try {
		const res = await axios.delete('/slots/inclinic', { data: { slotTimeId } });
		if (res.data.success) {
			showNotification('Success', 'The slot has been deleted successfully', 'success');
			dispatch(getInclinicSlots());
			// dispatch({
			// 	type: DELETE_INCLINIC_SLOT,
			// 	payload: inclinicSlotId,
			// });
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPrescriptionPad = () => async (dispatch) => {
	console.log('running getPrescriptionPad');
	try {
		const res = await axios.get('/doctor/profile/prescriptionPad');
		if (res.data.success) {
			dispatch({
				type: SET_PRESCRIPTION_PAD,
				payload: res.data.prescriptionPad,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePrescriptionPad = (prescPad) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/prescriptionPad', prescPad);
		if (res.data.success) {
			dispatch({
				type: SET_PRESCRIPTION_PAD,
				payload: prescPad,
			});
			showNotification('Success', 'The prescription pad is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getBillingPad = () => async (dispatch) => {
	console.log('running getBillingPad');
	try {
		const res = await axios.get('/billing-pad');
		if (res.data.success) {
			dispatch({
				type: SET_BILLING_PAD,
				payload: res.data.billingPad,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateBillingPad = (newPad) => async (dispatch) => {
	try {
		const res = await axios.post('/billing-pad', newPad);
		if (res.data.success) {
			dispatch({
				type: SET_BILLING_PAD,
				payload: newPad,
			});
			showNotification('Success', 'The billing pad is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getTeleConSlots = () => async (dispatch) => {
	console.log('running getTeleConSlots');
	try {
		const res = await axios.get('/slots');
		if (res.data.success) {
			dispatch({
				type: SET_TELECON_SLOTS,
				payload: res.data.slots,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTeleConSlot = (newSlot) => async (dispatch) => {
	try {
		const res = await axios.post('/slots', newSlot);
		if (res.data.success) {
			dispatch(getTeleConSlots());
			showNotification(
				'Success',
				'The Teleconsultation slot is created successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateTeleConSlot = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/slots', updateBody);
		if (res.data.success) {
			dispatch(getTeleConSlots());
			// dispatch({
			// 	type: UPDATE_TELECON_SLOT,
			// 	payload: updateBody,
			// });
			showNotification('Success', 'The Teleconsultation is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTeleConSlot = (slotTimeId) => async (dispatch) => {
	try {
		const res = await axios.delete('/slots', { data: { slotTimeId } });
		if (res.data.success) {
			showNotification(
				'Success',
				'The Teleconsultation Slot has been deleted successfully',
				'success',
			);
			dispatch(getTeleConSlots());
			// dispatch({
			// 	type: DELETE_TELECON_SLOT,
			// 	payload: inclinicSlotId,
			// });
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTeleConSlotTotal = (slotId) => async (dispatch) => {
	try {
		const res = await axios.delete('/slots/total', { data: { slotId } });
		if (res.data.success) {
			showNotification(
				'Success',
				'The Teleconsultation Slot has been deleted successfully',
				'success',
			);
			dispatch(getTeleConSlots());
			// dispatch({
			// 	type: DELETE_TELECON_SLOT,
			// 	payload: inclinicSlotId,
			// });
		}
	} catch (err) {
		console.log(err);
	}
};
