import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
// import { TourProvider } from '@reactour/tour';
import { useSelector, useDispatch } from 'react-redux';

import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import { authMenu, dashboardMenu } from '../menu';
import { getUserData } from '../actions/profileActions';
import axios from '../helpers/axios';
// import steps, { styles } from '../steps';

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	const loggedIn = useSelector((state) => state.auth.loggedIn);
	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		authMenu.login.path,
		authMenu.signUp.path,
		dashboardMenu.slideShow.path,
	];

	const dispatch = useDispatch();
	useEffect(() => {
		let mounted = true;

		const fetchAuth = () => {
			if (selectedOrg) axios.defaults.headers.common.organisationId = selectedOrg.id;
			if (mounted && !user && loggedIn) {
				dispatch(getUserData());
			}
			if (mounted && user && user.userType === 'staff' && !selectedOrg) {
				dispatch(getUserData());
			}
		};

		fetchAuth();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<div
					ref={ref}
					className='app'
					style={{
						backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
						zIndex: fullScreenStatus && 1,
						overflow: fullScreenStatus && 'scroll',
					}}>
					<Routes>
						{withOutAsidePages.map((path) => (
							<Route key={path} path={path} />
						))}
						<Route
							path='*'
							element={
								loggedIn ? (
									<Aside />
								) : (
									window.location.url !== '/auth-pages/login' && (
										<Navigate to='/auth-pages/login' />
									)
								)
							}
						/>
					</Routes>
					<Wrapper />
				</div>
				<Portal id='portal-notification'>
					<ReactNotification />
				</Portal>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
