import { LOADING_USER, AUTH_LOGIN, AUTH_LOGOUT, SET_USER, UPDATE_USER, SET_OTP_KEY } from './types';

const initialState = {
	loggedIn: false,
	loading: false,
	error: null,
	otpKey: null,
	user: null,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_USER:
			return {
				...state,
				loading: true,
			};

		case AUTH_LOGIN:
			return {
				...state,
				loggedIn: true,
				loading: false,
			};

		case AUTH_LOGOUT:
			return initialState;

		case SET_OTP_KEY:
			return {
				...state,
				otpKey: action.payload,
				loading: false,
			};

		case SET_USER:
			return {
				user: action.payload,
				loading: false,
				loggedIn: true,
			};

		case UPDATE_USER:
			var user = state.user;
			if (action.payload.name) {
				user.Vendor_detail.name = action.payload.name;
				delete action.payload.name;
			}
			if (action.payload.gender) {
				user.Vendor_detail.gender = action.payload.gender;
				delete action.payload.gender;
			}
			if (action.payload.profileImage) {
				user.Vendor_detail.profileImage = action.payload.profileImage;
				delete action.payload.profileImage;
			}

			return {
				user: { ...action.payload, ...user },
				loading: false,
				loggedIn: true,
			};

		default:
			return state;
	}
};

export default authReducer;
