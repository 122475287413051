import { SET_DOCTORS, LOADING_DOCTORS, CREATE_DOCTOR, UPDATE_DOCTOR, DELETE_DOCTOR } from './types';

const initialState = {
	doctors: [],
	error: null,
	loading: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_DOCTORS:
			return {
				...state,
				loading: true,
			};

		case SET_DOCTORS:
			return {
				...state,
				doctors: action.payload || [],
				loading: false,
			};

		case CREATE_DOCTOR:
			return {
				...state,
				doctors: [action.payload, ...state.doctors],
			};

		case UPDATE_DOCTOR:
			const doctorIdx = _.findIndex(state.doctors, (doc) => doc.id === action.payload.id);
			return {
				...state,
				doctors: [
					...state.doctors.slice(0, doctorIdx),
					action.payload,
					...state.doctors.slice(doctorIdx + 1),
				],
			};

		case DELETE_DOCTOR:
			return {
				...state,
				doctors: _.reject(state.doctors, (doc) => doc.id === action.payload.id),
			};

		default:
			return state;
	}
};

export default authReducer;
