import {
	SET_PHARMACY_VENDORS,
	LOADING_PHARMACY_VENDORS,
	CREATE_PHARMACY_VENDOR,
	UPDATE_PHARMACY_VENDOR,
	DELETE_PHARMACY_VENDOR,
} from './types';

const initialState = {
	pharmacyVendors: [],
	error: null,
	loading: false,
};

const pharmacyVendorReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PHARMACY_VENDORS:
			return {
				...state,
				loading: true,
			};

		case SET_PHARMACY_VENDORS:
			return {
				...state,
				pharmacyVendors: action.payload || [],
				loading: false,
			};

		case CREATE_PHARMACY_VENDOR:
			return {
				...state,
				pharmacyVendors: [action.payload, ...state.pharmacyVendors],
			};

		case UPDATE_PHARMACY_VENDOR:
			state.pharmacyVendors = state.pharmacyVendors.filter((o) => o.id != action.payload.id);
			return {
				pharmacyVendors: [action.payload, ...state.pharmacyVendors],
				...state,
			};

		case DELETE_PHARMACY_VENDOR:
			state.pharmacyVendors = state.pharmacyVendors.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		default:
			return state;
	}
};

export default pharmacyVendorReducer;
