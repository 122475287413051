import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/styles.scss';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import configureStore from './store/configureStore';
import axios from './helpers/axios';
import { logout } from './actions/authActions';
import { AUTH_LOGIN } from './reducers/types';

const { Persistor, store } = configureStore();

const token = localStorage.ehrToken;
if (token) {
	store.dispatch({ type: AUTH_LOGIN });
	axios.defaults.headers.common.Authorization = token;
} else {
	store.dispatch(logout());
}

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<Provider store={store}>
					<PersistGate loading={null} persistor={Persistor}>
						<App />
					</PersistGate>
				</Provider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
