export const dashboardMenu = {
	slides: {
		id: 'slides',
		text: 'Slides',
		path: '/',
		icon: 'Dashboard',
		subMenu: {
			addSlide: {
				id: 'addSlide',
				text: 'Add Slide',
				path: 'slides/add',
				icon: 'Add',
			},
			viewSlides: {
				id: 'viewSlides',
				text: 'View Slides',
				path: 'slides/view',
				icon: 'ListAlt',
			},
		},
	},
	slideShow: {
		id: 'slideShow',
		text: 'Play Slideshow',
		path: '/slideshow',
		icon: 'PlayCircle',
		subMenu: null,
	},
	logout: {
		id: 'logout',
		text: 'Logout',
		path: '/logout',
		icon: 'Logout',
		subMenu: null,
	},
};

export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};
