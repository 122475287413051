import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';

import { SET_SELECTED_ORGANISATION } from '../../../reducers/types';
import { getOrganisations } from '../../../actions/organisationActions';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const dispatch = useDispatch();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const loggedIn = useSelector((state) => state.auth.loggedIn);
	const organisations = useSelector((state) => state.organisation.organisations);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	const handleChangeSelectedOrg = (org) => {
		if (org) {
			dispatch({
				type: SET_SELECTED_ORGANISATION,
				payload: org,
			});
		} else {
			dispatch({
				type: SET_SELECTED_ORGANISATION,
				payload: organisations[0] || null,
			});
		}
	};

	useEffect(() => {
		let mounted = true;

		const fetchOrganisations = () => {
			if (mounted && loggedIn) {
				dispatch(getOrganisations());
			}
		};

		fetchOrganisations();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Organisation Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								style={{
									fontSize: '12px',
								}}
								icon='building'
								aria-label='Change Organisation'>
								{selectedOrg ? selectedOrg.name : 'Not Selected'}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							{organisations.map((i) => (
								<DropdownItem key={i.id}>
									<Button
										icon={'building'}
										onClick={() => handleChangeSelectedOrg(i)}>
										{i.name}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={getLangWithKey(i18n.language)?.icon}
								aria-label='Change language'
								data-tour='lang-selector'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{afterChildren}
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
