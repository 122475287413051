import axios from 'axios';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
});

instance.interceptors.response.use(
	function (res) {
		return res;
	},
	function (error) {
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='x-circle' size='lg' className='me-1' />
				<span>Error</span>
			</span>,
			error.response ? error.response.data?.errors?.[0] : error.message,
			'danger',
		);
		return Promise.reject(error);
	},
);

export default instance;
