import {
	SET_PHARMACIES,
	LOADING_PHARMACIES,
	CREATE_PHARMACY,
	UPDATE_PHARMACY,
	DELETE_PHARMACY,
} from './types';

const initialState = {
	pharmacies: [],
	error: null,
	loading: false,
};

const pharmacyReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PHARMACIES:
			return {
				...state,
				loading: true,
			};

		case SET_PHARMACIES:
			return {
				...state,
				pharmacies: action.payload || [],
				loading: false,
			};

		case CREATE_PHARMACY:
			return {
				...state,
				pharmacies: [action.payload, ...state.pharmacies],
			};

		case UPDATE_PHARMACY:
			state.pharmacies = state.pharmacies.filter((o) => o.id != action.payload.id);
			return {
				pharmacies: [action.payload, ...state.pharmacies],
				...state,
			};

		case DELETE_PHARMACY:
			state.pharmacies = state.pharmacies.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		default:
			return state;
	}
};

export default pharmacyReducer;
