import {
	SET_ACCOUNT_VENDORS,
	LOADING_ACCOUNT_VENDORS,
	CREATE_ACCOUNT_VENDOR,
	UPDATE_ACCOUNT_VENDOR,
	DELETE_ACCOUNT_VENDOR,
} from './types';

const initialState = {
	accountVendors: [],
	error: null,
	loading: false,
};

const accountVendorReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_ACCOUNT_VENDORS:
			return {
				...state,
				loading: true,
			};

		case SET_ACCOUNT_VENDORS:
			return {
				...state,
				accountVendors: action.payload || [],
				loading: false,
			};

		case CREATE_ACCOUNT_VENDOR:
			return {
				...state,
				accountVendors: [action.payload, ...state.accountVendors],
			};

		case UPDATE_ACCOUNT_VENDOR:
			state.accountVendors = state.accountVendors.filter((o) => o.id != action.payload.id);
			return {
				accountVendors: [action.payload, ...state.accountVendors],
				...state,
			};

		case DELETE_ACCOUNT_VENDOR:
			state.accountVendors = state.accountVendors.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		default:
			return state;
	}
};

export default accountVendorReducer;
