import React, { lazy } from 'react';
import { authMenu, dashboardMenu } from '../menu';

const LANDING = {
	SLIDESHOW: lazy(() => import('../pages/dashboard/SlideShow')),
	ADD_SLIDE: lazy(() => import('../pages/slides/AddSlidePage')),
	VIEW_SLIDES: lazy(() => import('../pages/slides/ViewSlidePage')),
	LOGOUT: lazy(() => import('../pages/dashboard/LogoutPage')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
	LOGIN: lazy(() => import('../pages/auth/Login')),
};

const contents = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.slides.path,
		element: <LANDING.VIEW_SLIDES />,
		exact: true,
	},
	{
		path: dashboardMenu.slideShow.path,
		element: <LANDING.SLIDESHOW />,
		exact: true,
	},
	{
		path: dashboardMenu.slides.subMenu.addSlide.path,
		element: <LANDING.ADD_SLIDE />,
		exact: true,
	},
	{
		path: dashboardMenu.slides.subMenu.viewSlides.path,
		element: <LANDING.VIEW_SLIDES />,
		exact: true,
	},
	{
		path: dashboardMenu.logout.path,
		element: <LANDING.LOGOUT />,
		exact: true,
	},

	/**
	 * Auth Page
	 */
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
];

export default contents;
